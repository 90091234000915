import { useEffect, useState } from 'react'


export const useWindow = () => {

    const [ ancho, setAncho ] = useState(window.innerWidth);

    useEffect( () => {

        const cambios = () => {
            setAncho(window.innerWidth)
        }

        window.addEventListener('resize', cambios);
        
        return () => {
            
            window.addEventListener('resize', cambios);
        }
    }, []);

    return ancho
}