import React, {useContext, useState} from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Input, Select, message } from 'antd'
import { ReservaContext } from './context/reservaContext';
import { getCurrentDate } from './helpers/fechaActual';
import { AvailabilityObj } from './helpers/VuelosDisponibles';
import { ApiContext } from '../../context/ApiContext';
import { useWindow } from './hooks/useWindow';
import { Spinner } from '../ui/Spinner';
import { MsgAvailability } from '../ui/MsgAvailability';


const { Option } = Select;


export const FormBooking = ({listado = [], modificarListado}) => {     

    const [ showSpinner, setShowSpinner ] = useState(false); 
    const [ disebleButton, setDisableButton ] = useState(false); 

    const [ NotFligths, setNotFligths ] = useState(false); 

    const [reserva_init, setReserva_init] = useContext(ReservaContext);       
    const [auth, guardarAuth] = useContext(ApiContext);  

    const ancho = useWindow();

    // funcion para activar el formulario
    const onFinish = async ({ serial, originAirportCode, destinationAirportCode, agents, weight, Date, arrivalOn, 
        natureOfGoods, products, pieces }) => {  
            
        if( originAirportCode === destinationAirportCode){
            message.error('Origin y Dest Are The Same');
            return
        }
           
        setShowSpinner(true);
        setDisableButton(true);

        const consultarDisp = await AvailabilityObj(agents, destinationAirportCode, originAirportCode, weight, Date, auth.token, products);
        modificarListado(consultarDisp); 

        if(listado.length === 0){
            setNotFligths(true);
        }

        setShowSpinner(false);
        setDisableButton(false);
       
        setReserva_init({
            agentAccountNumber: 'UTST001',
            airWaybill: {
                prefix: '279',
                referenceType: 'AIR WAYBILL',
                serial: serial
            },
            destinationAirportCode: destinationAirportCode,
            natureOfGoods: natureOfGoods,
            originAirportCode: originAirportCode,
            pieces: Number(pieces),
            segments: [],
            weight:{ amount: weight, unit: 'KG' },
            agents,
            productCode: products,
            salesAreaCode: 'MIA'
        });    
        
    };   

    return (
        <>

            <div className='back'>            
                <Link to='/form'>Back</Link>   
                <strong> New Booking </strong>
            </div> 

            <p style={{padding: '0rem 0.8rem', fontWeight: '600'}}>Enter the information to create a new booking</p>

         <Form                   
            name="horizontal_login" 
            layout="inline"  
            size='small'            
            onFinish={onFinish} 
            colon={false}                                         
          >

         <div  className='seccionForm'>

            <div className='inputsForm'>            

                <div className='inputsAwbSerial'>                     

                    <Form.Item  
                        label="Awb"                  
                        name="airlinePrefix"   
                        style={{ margin: '0.5rem 0rem', width: ancho > 768 ? '30%' : '40%', padding: '0.1rem', }} 
                        labelCol={{
                            span: 8,
                        }}                     
                        wrapperCol={{
                            span: 11,
                        }}           
                    >
                        <Input defaultValue='279' disabled  />

                    </Form.Item>
                    
                    <Form.Item  
                        name="serial" 
                        style={{ margin: '0.5rem 0rem', width: ancho > 768 ? '60%' : '60%', padding: '0.1rem', }}                     
                        wrapperCol={{
                            span: 14,
                        }} 
                        rules={[
                            {len: 8, message: 'AWB must be 8 digits'}
                        ]}
                        hasFeedback                 
                    >
                        
                        <Input placeholder='Number' />

                    </Form.Item> 
                </div> 

                <Form.Item 
                    label="Agent"
                    name="agents"
                    wrapperCol={{
                        span: 8,
                    }}  
                    style={{ margin: '0.5rem 0rem', padding: '0rem', }}     
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        }                        
                    ]}
                    hasFeedback
                >

                    <Select style={{ width: 130, }} >
                        {
                           
                           Object.entries(auth.agents || {}).map(([clave, valor]) => (

                                <Option key={clave} value={clave}>{valor}</Option>
                           ))
                            
                        }
                    </Select>

                </Form.Item>               
            </div>  

            <div className='inputsForm'>
           
                <Form.Item 
                    label="Origin"
                    name="originAirportCode"
                    wrapperCol={{
                        span: 10,
                    }}  
                    style={{ margin: '0.5rem 0rem', padding: '0rem',}}     
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        },                       
                    ]}  
                    hasFeedback              
                >
                    <Select style={{ width: 125, }} >
                        {
                           
                           Object.entries(auth.origins || {}).map(([clave, valor]) => (

                                <Option key={clave} value={clave}>{valor}</Option>
                           ))
                            
                        }
                    </Select>

                </Form.Item>

                <Form.Item 
                    label="Destination"
                    name="destinationAirportCode"
                    wrapperCol={{
                        span: 10,
                    }}  
                    style={{ margin: '0.5rem 0rem', padding: '0rem'}}  
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        }                        
                    ]}
                    hasFeedback
                >

                    <Select style={{ width: 125, }} >
                        {
                           
                           Object.entries(auth.destinations || {}).map(([clave, valor]) => (

                                <Option key={clave} value={clave}>{valor}</Option>
                           ))
                            
                        }
                    </Select>

                </Form.Item>
            </div>
        </div>

        <div  className='seccionForm'>
            <div className='inputsForm'>
                <Form.Item
                    label="Nature Of Goods"
                    name="natureOfGoods" 
                    style={{ margin: '0.5rem 0rem', padding: '0rem',}}  
                    wrapperCol={{
                        span: 8,
                    }} 
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        },
                    ]}
                    hasFeedback              
                >

                    <Select style={{ width: ancho > 768 ? 130 : 100,}} >
                        {
                            // convierto el objeto en un array                           
                        Object.entries(auth.shc).map(([clave, valor]) => (

                                <Option key={clave} value={clave}>{valor}</Option>
                        ))
                            
                        }
                    </Select>

                </Form.Item>

                <Form.Item
                    label="Product"
                    name="products" 
                    style={{ margin: '0.5rem 0rem', padding: '0rem',}}  
                    wrapperCol={{
                        span: 8,
                    }} 
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        },
                    ]}
                    hasFeedback              
                >

                    <Select style={{ width: 150, }} >
                        {
                           
                           Object.entries(auth.products).map(([clave, valor]) => (

                                <Option key={clave} value={clave}>{valor}</Option>
                           ))
                            
                        }
                    </Select>

                </Form.Item>
            </div>

            <div className='inputsForm'>
                
                <Form.Item
                    label='Weight'
                    name='weight' 
                    style={{ margin: '0.5rem 0rem', padding: '0rem' }}  
                    wrapperCol={{
                        span: 10,
                    }}                                 
                    rules={[
                        {
                            required: true,
                            message: 'Required',                           
                        },
                        {
                            pattern: /^[0-9]+$/,
                            message: 'can only include numbers.',
                        },                   
                       
                    ]}
                    hasFeedback
                >
                    <Input type='text'  placeholder='LB*' />

                </Form.Item>

                <Form.Item
                    label="Pieces"
                    name="pieces" 
                    wrapperCol={{
                        span: 10,
                    }}  
                    style={{ margin: '0.5rem 0rem', padding: '0rem', width: ancho > 768 ? '22%' : '100%'}}                     
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        },
                        {
                            pattern: /^[0-9]+$/,
                            message: 'can only include numbers.',
                        },
                        {max: 3}
                    ]}
                    hasFeedback              
                >
                    <Input type='number' placeholder='pieces' />

                </Form.Item>
            </div>

            <div className='inputsForm'>
                <Form.Item
                    label="Departure"
                    name="Date"   
                    style={{ margin: '0.5rem 0rem', padding: '0rem', width: ancho > 768 ? '27%' : '100%'  }}  
                    rules={[
                        { required: true, message: 'Required'}
                    ]}
                    wrapperCol={{
                        span: 10,
                    }}               
                > 
                    <Input type='date'  min={getCurrentDate()} />
                </Form.Item>

                <Form.Item
                    label="Arrival"
                    name="arrivalOn"   
                    style={{ margin: '0.5rem 0rem' , width: ancho > 768 ? '21%' : '100%' }}  
                    wrapperCol={{
                        span: 13
                    }}               
                > 
                 <Input  type='date' min={getCurrentDate()} />
                </Form.Item>
            </div>
        </div>

        <div className='ContenedorSearchFligths'>

            <Form.Item >                        
                <Button
                    type="primary"
                    htmlType="submit"     
                    style={{ 
                        backgroundColor: '#2981C4', 
                        color: 'white', 
                        margin: '0.5rem 0rem', 
                        
                    }}    
                    disabled={disebleButton}                               
                >
                    Search Flights
                </Button>                    
            </Form.Item> 
        </div>

               
    </Form>          
            
        {
            (showSpinner) &&  <div> <Spinner /> </div>                                 
        }

        {
            (listado.length === 0 && NotFligths) && <MsgAvailability  />
        }   
            
    </>
    )
}