import { TablaReserva } from '../TablaReserva';

export const tracking = ( booking = {}) => {

    const data = booking.booking;

    const { airlinePrefix, chrgweight, destination, events, natureOfGoods, origin, 
        pieces, routingSegments, serialNumber, volume, weight} = data;

    let summary = [];    

    summary = [
        <span>
           <strong>Air Waybill:</strong>  {airlinePrefix} - {serialNumber} 
        </span>,
        <span>           
            <strong>Status:</strong>  {routingSegments[0].actionStatus?.description ?? ''}           
        </span>,
        <span>           
            <strong>Route:</strong>  {origin?.code} - {destination?.code}           
        </span>,
        <span>           
            <strong>Pieces/Weight/Volume:</strong>{' '} 
            {pieces}/{weight?.amount}{weight?.unit}/ {volume?.amount}{volume?.unit}            
        </span>,
        <span>           
            <strong>Charged Weight:</strong>  {chrgweight?.amount} {chrgweight?.unit}           
        </span>,
        <span>           
            <strong>Goods:</strong>  {natureOfGoods}           
        </span>,
        <br />,  
        <TablaReserva key={1} data={events[0]}/>,      
             
    ]

    return summary;
}