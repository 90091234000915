
import React from 'react'
import { Link } from 'react-router-dom';
import { ContenedorHero } from '../layout/ContenedorHero'
import { LogoAeronex } from '../layout/LogoAeronex'
import { Footer } from '../layout/Footer'
import { FormRegistro } from './FormRegistro'

export const Registro = () => {

  return (

    <div className='contenedor_login'>
        <ContenedorHero>

            <LogoAeronex /> 

            <FormRegistro />

            <div className='login'>
              <div className='acciones'>            
                <p>
                    Return to {' '}
                  <Link  to='/login'> Login </Link>      
                </p>
              </div> 
            </div>
           
            <Footer />     
        </ContenedorHero>  
    </div>
  )
}


