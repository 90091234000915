import React from 'react'
import { useWindow } from '../reservas/hooks/useWindow'

export const Footer = () => {

    const ancho = useWindow();

  return (
    <div className='logo' > 
        {/* <span className='year'>{'© '} {new Date().getFullYear()} - </span> */}
          
          <div className={ ancho < 768 ? 'login' : ''}  style={{ paddingTop: ancho < 768 ? '2rem' : 0}}>
            <strong className='year'>CARGO REPRESENTATIVE</strong> {''}          
          </div>

          <div className={ ancho < 768 ? 'login' : ''}>
            <img className='footer_logo' src={require('../ui/img/JetBlueCargo.png')} alt="Logo" />
          </div>
          
    </div>
  )
}


