
import { Typography} from 'antd';
import { Footer } from '../layout/Footer';
import { LogoAeronex } from '../layout/LogoAeronex';
import { ContenedorHero } from '../layout/ContenedorHero';
import { FormRestablecer } from './FormRestablecer';


export const Restablecer = () => { 

    return(
      <>         
        <div className='contenedor_login'>

          <ContenedorHero>
            <LogoAeronex />
            <FormRestablecer />
            <br />     
            <Footer />
          </ContenedorHero>
         
        </div>        
      </>
    )
}