
import { Routes, Route, Navigate } from 'react-router-dom';
import { Login} from '../components';
import { ReservasRoutes } from '../components/reservas/routes/ReservasRoutes';
import { TrackingHome } from '../components/Modal/TrackingHome';
import { ShareTracking } from '../components/tracking/ShareTracking'
import { Olvide } from '../components/auth/Olvide';
import { Registro } from '../components/auth/Registro';
import { Restablecer } from '../components/auth/Restablecer';


export const AppRouter = () => {
    return(
        <>      
            <Routes> 
                <Route  path='/' element={<Login />} />                        
                <Route path='login' element={<Login />} />                             
                <Route path='/reset' element={<Olvide />} />                             
                <Route path='/resetpassword' element={<Restablecer />} />                             
                <Route path='/create' element={<Registro />} />                             
                <Route path='/form/*' element={<ReservasRoutes />} /> 
                <Route path='/tracking' element={<TrackingHome />} /> 
                <Route path='/awb-track' element={<ShareTracking />} /> 
                <Route path="*" element={<Navigate to="/login" replace />} />                
            </Routes>        
               
        </>
    )
} //<Navigate to='/login' />