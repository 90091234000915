import { Link } from 'react-router-dom';
import { TrackingHome } from '../Modal/TrackingHome';
import { Footer } from '../layout/Footer';
import { FormLogin } from './FormLogin';
import { LogoAeronex } from '../layout/LogoAeronex';
import { ContenedorHero } from '../layout/ContenedorHero';



export const Login = () => {

    return(
      <>         
        <div className='contenedor_login'>
          <ContenedorHero>

            <LogoAeronex />
            <br />
            <FormLogin />

            <div className='login'>
              <div className='acciones'>            
                <p>
                    ¿No account? {' '}
                  <Link to='/create'> Sing Up </Link>      
                </p>
              </div> 
            </div>

            <TrackingHome />   
            <Footer />
          </ContenedorHero>
         
        </div>        
      </>
    )
}