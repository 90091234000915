import { useContext, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Form, Input, Row, Col } from 'antd';
import { DetalleReserva } from './DetalleReserva';
import { ApiContext } from '../../context/ApiContext';
import { Spinner } from '../ui/Spinner';
import { ErrorMsg } from '../Modal/ErrorMsg';
import { consultar_reserva } from './helpers/consultar_reserva';


export const Reserva = ( cleanBooking = false) => {

    const [ auth, guardarAuth] = useContext(ApiContext);
    const { token } = auth;
    
    const [ booking, setBooking] = useState({});
    const [ showSpinner, setShowSpinner] = useState(false);

    const [ showError, setShowError] = useState(false);
    const location = useLocation(); 
    
    const onFinish = ({serialNumber}) => {
        consultar(serialNumber);    
    };

    useEffect( () => {

        if(cleanBooking){
            setBooking({});
        } 
               
    }, [cleanBooking]);


    const consultar = async (serialNumber) => {

        setBooking({});

        setShowError(false);  
        setShowSpinner(true); 

        const respuesta = await consultar_reserva(serialNumber);
        
        setBooking(respuesta);   
        setShowSpinner(false);

        if(!respuesta){
            setShowSpinner(false);     
            setShowError(true);  
        }
        
    }

    const Share = () => {

        const url = window.location.origin; // obtengo la url del servidor
        const awbParams = `/awb-track?airlinePrefix=279&serialNumber=${booking.serialNumber}`       

        if (navigator.share) {
            navigator.share({
              title: 'Aeronex Cargo',
              text: 'AWB Tracking',
              url: `${url}${awbParams}`,
            })
            .then(() => console.log('Successfully shared'))
            .catch((error) => console.log('Error sharing', error));
          }
    }

    if(showSpinner){
        return <Spinner />
    }
      
    return(
        <>     
            {
                (showError) && <ErrorMsg  ver={true}/>
            }  

            <Row gutter={[32, 16]}>
                <Form
                    name="horizontal"
                    layout="inline"
                    size='small'  
                    labelCol={{
                        span: (window.innerWidth > 720 ? 20 : 8),
                    }}
                    wrapperCol={{
                        span: 10,
                    }}
                    initialValues={{
                    remember: true,
                    }}
                    onFinish={onFinish}                
                    autoComplete="off"
                >
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', width:400, minWidth: 400}}>
                        <span style={{marginLeft: 10, minWidth:100}}> AWB Number </span>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', width:50, minWidth: 50}}>

                            <Form.Item  
                                name="airlinePrefix"                       
                                wrapperCol={{
                                    span: 6,
                                }}            
                            >
                                <Input 
                                    type='text'
                                    defaultValue='279'    
                                    disabled                               
                                />

                            </Form.Item>
                        </div>
                        <span style={{marginLeft: 0}}> – </span>


                        <Col xs={14} sm={14} md={14} lg={14} xl={14}>

                            <Form.Item  
                                name="serialNumber" 
                                wrapperCol={{
                                    span: 20,
                                }}  
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input AWB Number',
                                    },
                                    {
                                        pattern: /^[0-9]+$/,
                                        message: 'Numeric value required',
                                    }, 
                                    {max: 8, message: 'Max 8 digits'}
                                ]}
                                hasFeedback                 
                            >
                                <Input  
                                    type='text'
                                    placeholder='number'                                           
                                />

                             </Form.Item>

                        </Col>               
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', width:50, minWidth: 50}}>
                <Form.Item className='btn'>

                    <Button 
                        type="primary" 
                        htmlType="submit" 
                        style={{
                            backgroundColor: '#1D2758', 
                            color: 'white',
                             marginLeft: (window.innerWidth > 720 ? 10 : 10)
                        }} 
                    > 
                        Submit
                    </Button>

                </Form.Item>
                <Form.Item className='btn'>
                    <Button 
                        type="primary" 
                        onClick={Share}
                        disabled={Object.entries(booking).length === 0}
                        style={{
                            backgroundColor: '#1D2758', 
                            color: 'white', 
                            marginLeft: (window.innerWidth > 720 ? 0 : 0)
                        }} 
                    > 
                        Share
                    </Button>
                </Form.Item>
                </div>
            </Form>        
        </Row>

            {
               (Object.entries(booking).length > 0) && <DetalleReserva booking={booking} />           
            } 
       </>
    )
}