
import React from 'react'

export const ContenedorHero = ({ children }) => {
  
    return (
 
        <div className='hero'>
            { children }
        </div>    
   )
}


