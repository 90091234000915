import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Form, Input, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { ApiOlvide } from '../../api/login';
import { MsgError } from '../ui/MsgError';
import { Spinner } from '../ui/Spinner';
import { Alert } from '../ui/Alert';

export const FormRestablecer = () => {

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const token = params.get('token');

    const [showError, setShowError] = useState(false);
    const [btnEnviar, setBtnEnviar] = useState(false);

    const [showSpinner, setShowSpinner] = useState(false);
    const navigate = useNavigate();   

    const onFinish = async ({password, password2}) => {
    
        try {
          
          setBtnEnviar(true);
          setShowSpinner(true);

          if( password !== password2) {

            message.error('The passwords are different');
            setShowSpinner(false);
            setBtnEnviar(false);   
            return

          }
          
          const respuesta = await ApiOlvide.post('resetpassword', {
            token,
            'newpwd': password,
            'newpwd2': password2
          });
         
          setShowSpinner(false);  
          
           Alert(
                'Success', 
                'Cambio Exitoso',
                'success'
            );

            setTimeout( () => {
                navigate('/');
            }, 3000);
         
          
        } catch (error) {     
    
          setShowError(true); 
          setShowSpinner(false);
          setBtnEnviar(false);      
          
        }     
        
      };  

    if(showSpinner){
         return <Spinner />
    }

  return (

    <div className='login'>                

        {
            (showError) && <MsgError mostrar={true}  />                     
        }

        <Form
            name="basic"
            size='small'
            labelCol={{
                span: 4,
            }}
            wrapperCol={{
                span: 20,
            }}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item          
                label={ <LockOutlined style={{fontSize: 18}} />}
                name="password"               
                rules={[
                {
                    required: true,
                    message: 'Input password',
                },
                ]}
            >
                <Input.Password  placeholder='Password' />
            </Form.Item>

            <Form.Item
                label={<LockOutlined style={{fontSize: 18}}/>}
                name="password2"
                rules={[
                    {
                    required: true,
                    message: 'Confirm your password', 
                    },
                ]}
            >
                <Input.Password  placeholder='Confirm Password'/>
            </Form.Item>

            <br />
            <div className='contenedor_btn'>

                <Form.Item>
                    <Button 
                        type="primary" 
                        htmlType="submit"
                        style={{backgroundColor: '#1D2758', color: 'white', }}
                        disabled={btnEnviar}
                    >
                        send
                    </Button>
                </Form.Item>
            </div>
        </Form> 
    </div> 
  )
}