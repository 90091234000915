import React, {useContext, useState} from 'react';
import { Ejemplo } from '../vuelos/Ejemplo';
import { BtnEnviarReserva } from './BtnEnviarReserva';
import { ReservaContext } from './context/reservaContext';
import { ErrorAvailabilityFligth } from './ErrorAvailabilityFligth';
import { FormBooking } from './FormBooking';



export const NewBooking = () => {     

    const [ listado, setlistado ] = useState([]); 
    const [ btnEnviarReserva, setBtnEnviarReserva ] = useState(false); 

    const [ showSpinner, setShowSpinner ] = useState(false); 
    const [reserva_init, setReserva_init] = useContext(ReservaContext);       

    const modificarListado = ( newListado ) => {
        setlistado(newListado)
    }

    return (
        <>
            <div className='contenedorForm'>           

                <FormBooking 
                    listado={listado} 
                    modificarListado={modificarListado} 
                />
                                    
                {                         
                    (listado && listado.length > 0 ) ? 

                        <Ejemplo 
                            listado={listado} 
                            reserva={reserva_init}
                            btnEnviarReserva={btnEnviarReserva}
                            setBtnEnviarReserva={setBtnEnviarReserva}
                        />  
                        : (!showSpinner && !listado) && <ErrorAvailabilityFligth />            
                } 

                {
                    (btnEnviarReserva) && <BtnEnviarReserva />
                } 
                    
            </div>
        </>
    )
}