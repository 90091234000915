
import React from 'react'
import { ContenedorHero } from '../layout/ContenedorHero'
import { LogoAeronex } from '../layout/LogoAeronex'
import { FormOlvide } from './FormOlvide'
import { Footer } from '../layout/Footer'


export const Olvide = () => {

  return (
    <div className='contenedor_login'>
       <ContenedorHero>
            <LogoAeronex /> 
            <FormOlvide />
            <Footer />     
       </ContenedorHero>       
    </div>
  )
}
