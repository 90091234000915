
import React, { useState } from 'react'
import { useNavigate, Link} from 'react-router-dom';
import { Button,  Form, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { ApiOlvide } from '../../api/login';
import { MsgError } from '../ui/MsgError';
import { Spinner } from '../ui/Spinner';
import { Alert } from '../ui/Alert';


export const FormOlvide = () => {

    const [showError, setShowError] = useState(false);
    const [btnEnviar, setBtnEnviar] = useState(false);

    const [showSpinner, setShowSpinner] = useState(false);
    const navigate = useNavigate();   

    const onFinish = async ({user}) => {
    
        try {
          
          setBtnEnviar(true);
          setShowSpinner(true);
          
          const { status } = await ApiOlvide.post('resetmessage', {
            username: user
          });
        
          setShowSpinner(false);  

          Alert(
              'Success', 
              'An email was sent with the reset link',
              'success'
          );

          setTimeout( () => {
              navigate('/');
          }, 3000);
                           
        } catch (error) {     
    
          setShowError(true); 
          setShowSpinner(false);
          setBtnEnviar(false);      
          
        }     
        
      };  

    if(showSpinner){
         return <Spinner />
    }

  return (

    <div className='login'>                

        {
            (showError) && <MsgError mostrar={true}  />                     
        }

        <Form
            name="basic"
            size="small"
            labelCol={{
                span: 4,
            }}
            wrapperCol={{
                span: 18,
            }}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item          
                label={ <UserOutlined style={{fontSize: 16}} />}
                name="user"               
                rules={[
                {
                    required: true,
                    message: 'Required',
                },
                ]}
            >
                <Input  placeholder='Username' />

            </Form.Item>

            <div className='contenedor_btn'>
                <Form.Item>
                    <Button 
                        type="primary" 
                        htmlType="submit"
                        style={{backgroundColor: '#1D2758', color: 'white', }}
                        disabled={btnEnviar}
                    >
                        Send
                    </Button>
                </Form.Item>
            </div>

            <div className='login'>
                <div className='acciones'>
                    <p>
                        Return to {' '}
                        <Link 
                            to='/login'
                            style={{paddingTop: 4, fontSize: 18}}
                        > 
                            Login
                        </Link>      
                    </p>                        
                 </div>
            </div>

        </Form> 
    </div> 
  )
}


