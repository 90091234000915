
import React, { useState } from 'react'
import { useNavigate, Link} from 'react-router-dom';
import { Button,  Form, Input } from 'antd';
import { UserOutlined, MailOutlined, ContactsOutlined, BankOutlined } from '@ant-design/icons';
import { MsgError } from '../ui/MsgError';
import { Spinner } from '../ui/Spinner';


export const FormRegistro = () => {

    const [showError, setShowError] = useState(false);
    const [btnEnviar, setBtnEnviar] = useState(false);

    const [showSpinner, setShowSpinner] = useState(false);
    const navigate = useNavigate();   

    const onFinish = async ({user}) => {
    
        try {
          
          setBtnEnviar(true);
          setShowSpinner(true);
          
        
          setShowSpinner(false);            
          navigate('/');
          
        } catch (error) {     
    
          setShowError(true); 
          setShowSpinner(false);
          setBtnEnviar(false);      
          
        }     
        
      };  

    if(showSpinner){
         return <Spinner />
    }

  return (

    <div className='login'>                

        {
            (showError) && <MsgError mostrar={true}  />                     
        }

        <Form
            name="basic"
            size="small"
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item          
                label={ <UserOutlined style={{fontSize: 16}} />}
                name="username"     
                className='registro'        
                rules={[
                {
                    required: true,
                    message: 'Required',
                },
                ]}
            >
                <Input  placeholder='Username' />
            </Form.Item>

            <Form.Item          
                label={ <UserOutlined style={{fontSize: 16}} />}
                name="name"   
                className='registro'              
                rules={[
                {
                    required: true,
                    message: 'Required',
                },
                ]}
            >
                <Input  placeholder='Name' />
            </Form.Item>

            <Form.Item          
                label={ <UserOutlined style={{fontSize: 16}} />}
                name="lastname"  
                className='registro'               
                rules={[
                {
                    required: true,
                    message: 'Required',
                },
                ]}
            >
                <Input  placeholder='Lastname' />
            </Form.Item>

            <Form.Item          
                label={ <MailOutlined style={{fontSize: 16}} />} 
                name="email"  
                className='registro'               
                rules={[
                {
                    required: true,
                    message: 'Required',
                },
                ]}
            >
                <Input  placeholder='email' />
            </Form.Item>

            <Form.Item          
                label={ <BankOutlined style={{fontSize: 16}} />}
                name="company" 
                className='registro'                
                rules={[
                {
                    required: true,
                    message: 'Required',
                },
                ]}
            >
                <Input  placeholder='Company' />
            </Form.Item>

            <Form.Item          
                label={ <ContactsOutlined style={{fontSize: 16}} />}
                name="agents" 
                className='registro'  
                style={{marginBottom: '1.4rem'}}              
                rules={[
                {
                    required: true,
                    message: 'Required',
                },
                ]}
            >
                <Input  placeholder='agents' />

            </Form.Item>

            <div className='contenedor_btn'>

                <Form.Item>
                    <Button 
                        type="primary" 
                        htmlType="submit"
                        style={{backgroundColor: '#1D2758', color: 'white', }}
                        disabled={btnEnviar}
                    >
                        Send
                    </Button>
                </Form.Item>

            </div>
        </Form> 
    </div> 
  )
}


