import React from 'react'

export const LogoAeronex = () => {
    
  return (
    <div className='login'> 
      <div className='logo'>
          <img  width='180' height='50' src={require('../ui/img/aeronexcargologo.png')} alt="JetBlue Cargo" />
      </div>  
    </div>
  )
}


